<!--设备分布统计-->
<template>
    <widget-framework v-if="dataLoaded" title="设备分布统计" :show-border="true" @click="$router.push('/equipment/index')">
        <template #content>
            <el-row style="height: 100%; width: 100%">
                <el-col :span="12" style="height: 100%; ">
                    <v-chart :options="opt" :autoresize="true" style="width: 100%; height: 100%;"></v-chart>
                </el-col>
                <el-col :span="12" style="height: 100%">
                    <dv-scroll-board :config="config" style="width:100%;height:100%" />
                </el-col>
            </el-row>
        </template>
    </widget-framework>
    <dv-loading v-else></dv-loading>
</template>

<script>
import WidgetFramework from '@/components/widgets/WidgetFramework';
import WidgetBase from '@/components/widgets/WidgetBase';
import EchartBase from '@/components/widgets/EchartBase';
import {
    lightBlue,
    green,
    blue,
    headerBGC,
    oddRowBGC,
    evenRowBGC,
    yellow,
} from '@/components/widgets/constant';

export default {
    name: 'EquipmentDistribution',
    components: {
        WidgetFramework
    },
    mixins: [WidgetBase, EchartBase],
    data() {
        return {
            dataLoaded: false,
            opt: {
                title: {
                    show: false
                },
                tooltip: {
                    position: this.setTooltipPosition
                },
                legend: {
                    show: false
                },
                radar: {
                    name: {
                        textStyle: {
                            color: green,
                            fontSize: 12,
                            lineHeight: 14
                        },
                        formatter(text) {
                            let strLength = text.length;
                            if (strLength % 3 !== 0) {
                                text = text.replace(/\S{3}/g, (match) => {
                                    return match + '\n';
                                });
                            } else {
                                text = text.replace(/\S{3}/g, (match) => {
                                    return match + '\n';
                                });
                                strLength = text.length;
                                text = text.substring(0, strLength - 1);
                            }
                            return text;
                        }
                    },
                    indicator: [],
                    radius: 80,
                    axisLine: {
                        lineStyle: {
                            color: lightBlue
                        }
                    },
                    splitArea: {
                        show: false,
                    },
                    splitLine: {
                        lineStyle: {
                            color: lightBlue
                        }
                    }
                },
                series: []
            },
            config: {
                header: ['<span style="font-size: 14px">设备类型</span>', '<span style="font-size: 14px">数量/长度</span>'],
                headerBGC,
                oddRowBGC,
                evenRowBGC,
                rowNum: 6,
                data: [],
                index: false,
                columnWidth: [100, 120],
                align: ['center', 'center'],
                carousel: 'single'
            }
        };
    },
    mounted() {
        this.$client.getEquipmentStatistics().then((ret) => {
            const {data} = ret || {};
            const indicator = [];
            const items = [];
            data.forEach((item) => {
                items.push(item.value || 0);
                let name = '';
                let max = 0;
                switch (item.mainCategory) {
                    case 'MAIN_SUBSTATION':
                        name = '变电站';
                        max = 10;
                        break;
                    case 'SUBSTATION':
                        name = '箱式变电站';
                        max = 110;
                        break;
                    case 'TOWER_POLE':
                        name = '杆塔';
                        max = 1950;
                        break;
                    case 'TOWER_POLE_SWITCH':
                        name = '开关';
                        max = 230;
                        break;
                    case 'RING_MAIN_UNIT':
                        name = '户外开关站';
                        max = 110;
                        break;
                    case 'WIRE':
                        name = '线缆(km)';
                        max = 400;
                        break;
                    default:
                }
                item.name = name;
                item.max = max;
                indicator.push({
                    name,
                    max
                    //max: Math.ceil(Math.random() * 5) * (item.value || 1)
                });
            });
            this.opt.radar.indicator = indicator;
            this.opt.series.push(
                {
                    name: '',
                    type: 'radar',
                    itemStyle: {color: blue},
                    areaStyle: {color: blue},
                    data: [
                        {
                            value: items,
                            name: '设备数量'
                        }
                    ]
                }
            );
            data.forEach((item) => {
                const name = `<span style="color: ${lightBlue};font-size: 12px">${item.name}</span>`;
                const value = `<span style="color: ${yellow};font-size: 12px">${item.value}</span>`;
                this.config.data.push([name, value]);
            });
            this.dataLoaded = true;
        });
    }
};
</script>

<style scoped>

</style>
